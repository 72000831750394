import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import useApiAxios from "../../api/useApiAxios";
import cros from "../../assets/images/cros.svg";
import { useContext } from "react";
import { Context } from "../../context/Context";

const ActivityScaleModal = ({ show, handleClose }) => {
  const { userData } = useContext(Context);
  const { createFunctionalLabelApi } = useApiAxios();
  const [data, setData] = useState([]);

  const handleRadioChange = (event, id) => {
    const nextShapes = data.map((shape) => {
      if (!(shape._id === id)) {
        // No change
        return shape;
      } else {
        // Return a new circle 50px below
        return {
          ...shape,
          painLevel: event,
        };
      }
    });
    // Re-render with the new array
    setData(nextShapes);
  };

  useEffect(() => {
    setData(userData.activities);
  }, [userData.email]);

  const handleSubmit = async () => {
    try {
      let avg = 0;
      data.forEach((item) => (avg = Number(item.painLevel) + avg));
   
      await createFunctionalLabelApi({
        avgActivityLabel: Math.trunc(avg / data.length),
      });
      handleClose(); // Close the modal after successful submission
    } catch (error) {
      // Handle errors
      console.error("Failed to create pain label:", error);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      keyboard={false}
      style={{ width: "100vw !important", margin: "20px" }}
      dialogClassName="modal-90w"
      className="modal fade success-popup add-company"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          How is your ability to perform the following activities?
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        >
          <img src={cros} alt="" />
        </button>
      </div>
      <div className="modal-body text-start">
        {userData.activities.map((item) => (
          <div key={item._id}>
            <h3>{item.answer}</h3>
            <div
              className="mb-5 mt-3 pb-3 d-flex align-items-left "
              style={{ flexDirection: "column" }}
            >
              <span className="me-3 mb-3">Unable to perform activity</span>
              {[...Array(11)].map((_, index) => (
                <div key={index} className="form-check form-check-inline mb-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name={item._id}
                    id={`painLevel-${index}`}
                    value={index}
                    defaultChecked={item.painLevel === index}
                    onChange={(event) =>
                      handleRadioChange(event.target.value, item._id)
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`painLevel-${index}`}
                  >
                    {index}
                  </label>
                </div>
              ))}
              <span className="me-3 mt-3">
                Able to perform activity at the same level as before the injury,
                or problem
              </span>
            </div>
          </div>
        ))}
        <div className="col-md-12 mt-4 d-flex justify-content-center">
          <button
            className="btn addsubmit-btn green-btn mb-3 px-3 px-sm-5"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ActivityScaleModal;
